import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import EasyCamper from '../images/logos/easy_camper.png'
import Campster from '../images/logos/campster.png'
import Possl from '../images/logos/possl.png'
import CleverVans from '../images/logos/clever_vans.png'

const IMAGES = [
  { src: EasyCamper, alt: 'Easy Camper logo' },
  { src: Campster, alt: 'Campster logo' },
  { src: Possl, alt: 'Pössl Mobile logo' },
  { src: CleverVans, alt: 'Clever Mobile logo' },
]

const Wrapper = styled.div`
  padding: 0 40px 40px;

  ${media.lessThan('large')`
    flex: 1 0 34%;
    margin: 0 auto;
    max-width: 190px;
    padding: 0 30px 20px;
  `}
`

const LogoImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const PartnerLogos = () => (
  <>
    {IMAGES.map((logo, index) => (
      // Using index as key is fine here, as the order will never change
      <Wrapper key={index}>
        <LogoImage {...logo} />
      </Wrapper>
    ))}
  </>
)

export default PartnerLogos
