import styled from 'styled-components'
import media from 'styled-media-query'

const Button = styled.button`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.orange};
  text-transform: uppercase;
  width: 170px;
  height: 53px;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 1.1875rem;
  box-shadow: 2px 2px 4px #2a2a2a;
  transition: box-shadow 0.2s, transform 0.2s;

  &:hover {
    cursor: pointer;
    padding: 0;
    box-shadow: 1px 1px 2px #2a2a2a;
    transform: translateY(1px);
  }

  &:active {
    box-shadow: 0px 0px 0px #2a2a2a;
    transform: translateY(3px);
  }

  ${media.lessThan('medium')`
    width: 114px;
    height: 35px;
    font-size: 0.8125rem;
  `}
`

export default Button
