import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'
import { injectIntl, FormattedMessage } from 'react-intl'

import SEO from '../components/seo'
import Button from '../components/Button'
import Logos from '../components/PartnerLogos'
import { Link } from '../i18n'

const ContentContainer = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  user-select: none;
  padding-top: 40px;
  margin: auto 0;
`

const WelcomeContainer = styled.div`
  background: ${props => props.theme.colors.blackOverlay};
  align-self: flex-end;
  flex: none;
  display: flex;
  flex-direction: column;
  line-height: 1;
  padding: 25px 80px;
  margin-bottom: 35px;
  text-align: right;

  ${media.lessThan('medium')`
    padding: 20px 40px;
  `}

  ${media.lessThan('small')`
    padding: 20px 20px;
  `}
`

const WelcomeText = styled.span`
  font-family: BrushScriptMT, sans-serif;
  color: ${props => props.theme.colors.orange};
  font-size: 2.625rem; /* 42px */
  font-style: italic;

  margin-bottom: 10px;

  ${media.lessThan('medium')`
    font-size: 2.25rem;
  `}

  ${media.lessThan('small')`
    font-size: 1.875rem;
  `}
`

const VanFactoryText = styled.h1`
  font-size: 4.5625rem;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  text-transform: uppercase;

  ${media.lessThan('medium')`
    font-size: 3.375rem;
  `}

  ${media.lessThan('small')`
    font-size: 2.5rem;
  `}
`

const WhatWeDoContainer = styled.div`
  justify-content: center;
  text-transform: uppercase;
  font-size: 1.375rem;
  color: ${props => props.theme.colors.white};
  display: flex;

  ${media.lessThan('medium')`
    font-size: 1rem;
  `}

  ${media.lessThan('small')`
    font-size: 	0.8125rem;
  `}
`

const DividerDot = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: ${props => props.theme.colors.orange};
  border-radius: 50%;
  align-self: center;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.27);

  ${media.lessThan('medium')`
    width: 7px;
    height: 7px;
    margin-left: 10px;
    margin-right: 10px;
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 80px;

  ${media.lessThan('medium')`
    margin-right: 40px;
  `}

  ${media.lessThan('small')`
    margin-right: 25px;
  `}
`

const LogosContainer = styled.div`
  flex: none;
  display: flex;
  flex-flow: row wrap;
  padding-top: 30px;
  margin-top: -15px;
  align-items: center;

  ${media.lessThan('large')`
    margin-top: 0;
  `}
`

const IndexPage = ({ intl, data }) => (
  <>
    <SEO
      title={intl.formatMessage({ id: 'home' })}
      keywords={['campers', 'vans']}
    />
    <ContentContainer>
      <WelcomeContainer>
        <WelcomeText>
          <FormattedMessage id="welcomeTo" />
        </WelcomeText>
        <VanFactoryText>{data.site.siteMetadata.title}</VanFactoryText>
        <WhatWeDoContainer>
          <DividerDot />
          <FormattedMessage id="sale" />
          <DividerDot />
          <FormattedMessage id="rental" />
          <DividerDot />
          <FormattedMessage id="manufacture" />
        </WhatWeDoContainer>
      </WelcomeContainer>
      <ButtonContainer>
        <Link to="/contact/">
          <Button>
            <FormattedMessage id="contactUs" />
          </Button>
        </Link>
      </ButtonContainer>
    </ContentContainer>
    <LogosContainer>
      <Logos />
    </LogosContainer>
  </>
)

export default injectIntl(IndexPage)

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
